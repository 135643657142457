.box {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.boxChild {
  display: flex;
  justify-content: space-between;
}

.buttonSection {
  display: flex;
}

.box input {
  padding: 10px;
  width: 250px;
}

.box ul input {
  margin: 0 15px;
  width: 10px;
}

.box ul {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.taskList {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.taskHandle {
  align-items: center;
  display: flex;
  justify-content: end;
}

.box ul button {
  border-radius: 5px;
}
