.button {
  border: 1px solid white;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  padding: 8px;
  width: max-content;
}

.orange {
  background-color: orange;
}

.blue {
  background-color: #41b3e7;
}

.red {
  background-color: red;
}
