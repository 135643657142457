.dropdownItem {
  align-items: center;
  color: #cdcdcd;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 40px;
  list-style-type: none;
  margin: 0%;
}

.dropdownItem:hover {
  color: #ffc42a;
}

.iconContainer {
  display: flex;
  margin-left: 5px;
  min-width: 20px;
}

.icon {
  width: 20px;
}

.title {
  display: flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 15px;
}

.active {
  color: #ffc42a;
}
