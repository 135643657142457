.container {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

.containerIcon {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.title {
  color: #535353;
  display: flex;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: 47px;
  width: 200px;
}

.inputDiv {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  display: flex;
  height: 55px;
  width: 562px;
}

.inputDiv input {
  border: none;
  color: #7b7878;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 21px;
  line-height: 21px;
  margin-left: 15px;
  width: 490px;
}

.inputDiv input:focus-visible {
  outline: none;
}

.loup {
  margin-left: 20px;
}
