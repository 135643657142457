.sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 315px;
}

.sidebarWidth {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 400px;
}

.sidebarList {
  height: auto;
  margin-left: 63px;
  margin-top: 111px;
  padding: 0;
}

.sidebarRow:hover {
  background-color: white;
  border-left: 4px solid grey;
  cursor: pointer;
}

.active.sidebarRow {
  background-color: white;
}

.logo {
  margin-left: 63px;
  margin-top: 55px;
  width: 207px;
}
