.container {
  display: flex;
  height: 100vh;
}

.leftContent {
  display: flex;
  flex-direction: column;
}

.rightContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.upperContent {
  background-color: #f3f3f3;
  display: flex;
}

.downContent {
  background-color: #f3f3f3;
  display: flex;
  flex-grow: 1;
  padding: 20px;
}
