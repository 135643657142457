.loading {
  color: green;
  font-size: 25px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.data {
  font-size: 25px;
}
